import styled from '@emotion/styled'
import tw from 'twin.macro'

export const ButtonsGrid = styled.div`
  ${tw`
    grid
    items-center
    justify-center
    grid-cols-3
    sm:grid-cols-5
    sm:grid-rows-3
    gap-6
    lg:gap-y-4
    lg:gap-x-10
    mx-auto
  `}
`
