import styled from '@emotion/styled'
import tw from 'twin.macro'

export const AllAdminToolsSection = styled.section`
  ${tw`
    py-20
    md:py-32
    bg-green-100
    overflow-hidden
  `}
`
export const AllAdminToolsCta = styled.div`
  ${tw`
    pt-16
    md:pt-24
  `}
`

export const AllAdminToolsSectionFirstGrid = styled.div`
  ${tw`
    md:-mt-9
    flex
    flex-row
    items-end
    gap-4
  `}
  .security-check-up {
    ${tw`
      w-1/3
      md:w-1/4
      shadow-lg
      rounded-lg
    `}
  }
  .email-link {
    ${tw`
      w-4/6
      md:w-1/2
      shadow-lg
      rounded-lg
    `}
  }
  .email-link-inbox {
    ${tw`
      w-1/4
      shadow-lg
      rounded-lg
      hidden
      md:block
    `}
    @media (min-width: 1400px) {
      border-radius: 2.5rem;
    }
  }
`

export const AllAdminToolsSectionSecondGrid = styled.div`
  ${tw`
    mt-4
    flex
    flex-row
    items-start
    md:justify-center
    gap-4
  `}
  .sso-onboarding {
    ${tw`
      w-1/2
      md:w-1/3
      shadow-lg
      rounded-lg
    `}
  }
  .listing-organizations {
    ${tw`
      w-full
      md:w-1/2
      shadow-lg
      rounded-lg
    `}
  }
`
