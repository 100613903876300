import * as React from 'react'

function SvgPlugAndPlayIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.23941 1.72174C8.20195 2.76196 7.61935 4.17113 7.61935 5.64027C7.61935 7.1094 8.20195 8.51858 9.23941 9.55879L22.627 22.9307C23.5416 23.861 23.5416 25.3748 22.627 26.2736C21.6967 27.204 20.1987 27.204 19.2683 26.2736L12.5824 19.5877L14.2539 17.9162L8.6875 12.3341L8.11983 12.9018L5.89644 10.6626C5.59564 10.367 5.19075 10.2013 4.76898 10.2013C4.3472 10.2013 3.94231 10.367 3.64151 10.6626L1.43389 12.9018C0.818911 13.5483 0.818911 14.5102 1.43389 15.1251L3.64151 17.3485L3.10538 17.9162L8.6875 23.4826L10.359 21.8111L17.0449 28.5286C18.0862 29.5519 19.4877 30.1253 20.9477 30.1253C22.4076 30.1253 23.8092 29.5519 24.8504 28.5286C27.0108 26.3525 27.0108 22.8518 24.8504 20.7073L11.4628 7.31964C10.5482 6.38928 10.5482 4.87548 11.4628 3.97667C12.3932 3.04631 13.8912 3.04631 14.8215 3.97667L21.5075 10.6626L19.836 12.3341L25.4023 17.9162L25.97 17.3485L28.1934 19.5877C28.8084 20.2027 29.8176 20.2027 30.4483 19.5877L32.656 17.3485C33.2709 16.702 33.2709 15.7401 32.656 15.1251L30.4483 12.9018L30.9845 12.3341L25.4023 6.76773L23.7309 8.43921L17.0449 1.72174C16.0037 0.698414 14.6021 0.125 13.1422 0.125C11.6822 0.125 10.2807 0.698414 9.23941 1.72174ZM2.55347 14.0056L4.77686 11.7822L7.01602 14.0056L4.77686 16.2447L2.55347 14.0056ZM27.0738 16.2447L29.313 14.0056L31.5364 16.2447L29.313 18.4681L27.0738 16.2447Z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgPlugAndPlayIcon
