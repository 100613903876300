import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import {
  BlurryShapesWrapper,
  FirstShape,
  Illustration,
  IllustrationWrapper,
  SecondShape,
} from './style'

const HeroHomeIllustration = () => {
  return (
    <IllustrationWrapper>
      <Illustration>
        <StaticImage
          src="https://res.cloudinary.com/cryptr/image/upload/c_limit,dpr_1,f_auto,h_776,q_auto,w_842/v1655904175/Cryptr%20www/Landing%20pages/Home/Optimized%20images/hero_home_cryptr_gcsywo.png"
          alt="Cryptr connection with an SSO catalog"
          quality={95}
          formats={['auto', 'webp', 'avif']}
          placeholder="none"
        />
      </Illustration>
      <BlurryShapesWrapper>
        <FirstShape />
        <SecondShape />
      </BlurryShapesWrapper>
    </IllustrationWrapper>
  )
}

export default HeroHomeIllustration
