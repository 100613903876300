import styled from '@emotion/styled'
import tw from 'twin.macro'

export const ButtonWrapper = styled.div`
  svg {
    ${tw`
      h-20
      w-20
    `}
    color: #263850;
  }
  a {
    ${tw`
      
      cursor-pointer
      rounded-md
      h-24
      w-24
      sm:h-28
      sm:w-28
      p-4
      bg-blue-900
      flex
      items-center
      justify-center
      transform 
      transition 
      duration-500 
      hover:scale-110
      hover:opacity-75
    `}
    &.button-0, 
    &.button-2, 
    &.button-3, 
    &.button-5, 
    &.button-6, 
    &.button-8, 
    &.button-9, 
    &.button-11, 
    &.button-12, 
    &.ask-techno-button {
      ${tw`
        -mt-6
        sm:mt-auto
      `}
    }
    &.button-0,
    &.button-2,
    &.button-4,
    &.button-5,
    &.button-7,
    &.button-9,
    &.button-10,
    &.button-12,
    &.ask-techno-button {
      ${tw`
        sm:-mt-6
      `}
    }
  }
`
