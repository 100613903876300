import styled from '@emotion/styled'
import tw from 'twin.macro'

export const IllustrationWrapper = styled.div`
  ${tw`
    w-1/2
    h-full
    relative
    flex
    items-center
    hidden
    lg:block
  `}
`

export const Illustration = styled.div`
  ${tw`
    absolute
    z-20
    
  `}
  top:-300px;
`

export const BlurryShapesWrapper = styled.div`
  ${tw`
    w-full
    relative
    z-0
    opacity-75
  `}
`

export const Shape = styled.div`
  ${tw`
    absolute
    z-0
    bg-purple-300
    rounded-full
  `}
  filter: blur(200px);
`

export const FirstShape = styled(Shape)`
  background: #dff7ff;
  height: 890px;
  width: 890px;
  top: -200px;
`

export const SecondShape = styled(Shape)`
  ${tw`
    z-10
    opacity-75
  `}
  background: #5a0dff;
  height: 400px;
  width: 400px;
  left: 200px;
  top: 100px;
`
