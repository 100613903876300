import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { AllAdminToolsSectionFirstGrid, AllAdminToolsSectionSecondGrid } from './style'

const AllAdminTools = () => (
  <>
    <AllAdminToolsSectionFirstGrid>
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/c_limit,dpr_1,f_auto,h_385,q_auto,w_296/v1656580452/Cryptr%20www/Landing%20pages/Home/Optimized%20images/user_account_natif_sisnuo.jpg"
        alt="User Account - Natif"
        className="security-check-up"
        formats={['auto', 'webp', 'avif']}
        imgStyle={{ borderRadius: '0.5rem' }}
        quality={95}
        placeholder="none"
      />
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/c_limit,dpr_1,f_auto,h_800,q_auto,w_615/v1656580451/Cryptr%20www/Landing%20pages/Home/Optimized%20images/sign_in_desktop_sarbacane_tpzxj7.jpg"
        alt="Signup Desktop - Sarbacane"
        className="email-link"
        formats={['auto', 'webp', 'avif']}
        imgStyle={{ borderRadius: '0.5rem' }}
        quality={95}
        placeholder="none"
      />
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/c_limit,dpr_1,f_auto,h_800,q_auto,w_395/v1656580452/Cryptr%20www/Landing%20pages/Home/Optimized%20images/signup_mobile_talentview_r6ur7v.jpg"
        alt="Signup Mobile - TalentView"
        className="email-link-inbox"
        formats={['auto', 'webp', 'avif']}
        imgStyle={{ borderRadius: '0.5rem' }}
        quality={95}
        placeholder="none"
      />
    </AllAdminToolsSectionFirstGrid>
    <AllAdminToolsSectionSecondGrid>
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/c_limit,dpr_1,f_auto,h_800,q_auto,w_600/v1656580452/Cryptr%20www/Landing%20pages/Home/Optimized%20images/start_to_configure_your_sso_holivia_yvmkz9.jpg"
        alt="SSO Configuration - Holivia"
        className="sso-onboarding"
        formats={['auto', 'webp', 'avif']}
        quality={95}
        placeholder="none"
      />
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/c_limit,dpr_1,f_auto,h_1008,q_auto,w_690/v1656580452/Cryptr%20www/Landing%20pages/Home/Optimized%20images/listing_enterprise_connection_onboardings_udstj9.jpg"
        alt="Listing Enterprise Connection Onboardings"
        className="listing-organizations"
        formats={['auto', 'webp', 'avif']}
        imgStyle={{ borderRadius: '0.5rem' }}
        quality={95}
        placeholder="none"
      />
    </AllAdminToolsSectionSecondGrid>
  </>
)

export default AllAdminTools
