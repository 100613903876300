import React from 'react'
import { ButtonsGrid } from './style'
import { AskTechnoButton, TechnoButton } from './techno_button'
import { technoLogos } from '../../../cdn_images/logos'

const DeveloperCallToActions = () => {
  return (
    <ButtonsGrid>
      {technoLogos.map((logo, index) => {
        return <TechnoButton key={index} logoIndex={index} logoUrl={logo.url} logoAlt={logo.alt} />
      })}
      <AskTechnoButton />
    </ButtonsGrid>
  )
}

export default DeveloperCallToActions
