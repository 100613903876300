import * as React from 'react'

function SvgShieldIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={23} height={23} viewBox="0 0 23 23" fill="none" {...props}>
      <path
        d="M20.367 3.654L11.984.162a2.102 2.102 0 00-1.61 0L1.99 3.654A2.093 2.093 0 00.698 5.588c0 8.667 5 14.657 9.67 16.604a2.102 2.102 0 001.612 0c3.741-1.559 9.675-6.942 9.675-16.604 0-.847-.511-1.61-1.288-1.934zM11.18 19.485l-.005-16.634 7.68 3.2c-.144 6.61-3.584 11.4-7.675 13.434z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgShieldIcon
