import React from 'react'
import AllAdminTools from '../../components/all_admin_tools'
import CallToActionSection from '../../components/call_to_action_section'
import FourColsWithFeatures from '../../components/four_cols_features'
import Hero from '../../components/hero'
import Layout from '../../components/layout'
import SectionWrapper from '../../components/sectionWrapper'
import SectionH2 from '../../components/section_h2'
import SectionLogosBase from '../../components/section_logos_base'
import SvgUsersIcon from '../../components/svgs/icons/users_icon'
import HeroHomeIllustration from '../../components/hero/hero_illustration/home'
import SvgSendEmailIcon from '../../components/svgs/icons/send_email_icon'
import SvgPlugAndPlayIcon from '../../components/svgs/icons/plug_and_play_icon'
import SvgPadlockCloseIcon from '../../components/svgs/icons/padlock_close_icon'
import SectionH2Base from '../../components/section_h2_base'
import SvgShieldIcon from '../../components/svgs/icons/shield_icon'
import { homeLogos } from '../../cdn_images/logos'
import SectionValuePropositions from '../../components/section_value_propositions'
import DeveloperCallToActions from '../../components/developer/call_to_actions'

const featureIcons = [
  {
    feature01: <SvgSendEmailIcon color="#54D2C4" />,
  },
  {
    feature02: <SvgPadlockCloseIcon color="#54D2C4" />,
  },
  {
    feature03: <SvgPlugAndPlayIcon color="#54D2C4" />,
  },
  {
    feature04: <SvgUsersIcon color="#54D2C4" />,
  },
]

const HomePage = ({ pageContext: { data, localePath } }: any) => {
  const sectionH2 = data.body[2].section_H2_primary
  if (!sectionH2) return null

  return (
    <Layout
      helmetTitle={data.header_title.raw[0].text}
      helmetDescription={data.meta_description}
      helmetImage={data.meta_image.url}
      helmetUrl={`https://www.cryptr.co${localePath}/`}
    >
      <Hero
        background_shape=""
        call_to_action_color="main"
        pageID={data.slug}
        title={data.header_title.raw}
        description={data.header_description.raw}
        call_to_action={data.call_to_action}
        category={data.category}
      >
        <HeroHomeIllustration />
      </Hero>
      <SectionWrapper disabledPadding="top">
        <SectionValuePropositions
          alignment="align-left"
          slug={data.slug}
          valuePropositions={data.value_propositions}
        />
      </SectionWrapper>
      <SectionWrapper color="dark-blue" disabledPadding="padding-auto">
        <SectionLogosBase items={homeLogos} />
        <SectionH2
          slug={data.slug}
          title={data.body[2].section_H2_primary.title}
          description={data.body[2].section_H2_primary.description.raw}
          textAlignment="text-center"
          subhead={null}
          color="on-dark-background"
          gridColumns={false}
        />
      </SectionWrapper>
      <SectionWrapper color="light-green" disabledPadding="padding-auto">
        <SectionH2
          slug={data.slug}
          title={data.body[3].section_H2_primary.title}
          description={data.body[3].section_H2_primary.description.raw}
          call_to_action={true}
          call_to_action_text={data.body[3].section_H2_primary.cta}
          call_to_action_path={data.body[3].section_H2_primary.cta_path}
          textAlignment="text-left"
          subhead={null}
          gridColumns={true}
        >
          <FourColsWithFeatures
            featureIcons={featureIcons}
            feature={data.body[4].items}
            slug={data.slug}
          />
        </SectionH2>
        <AllAdminTools />
      </SectionWrapper>
      <SectionWrapper color="dark-blue" disabledPadding="padding-auto" identifier="developer">
        <SectionH2Base data={data.body[5]}>
          <DeveloperCallToActions />
        </SectionH2Base>
      </SectionWrapper>
      <CallToActionSection
        iconCol1={<SvgShieldIcon color="#54D2C4" />}
        iconCol2={<SvgUsersIcon color="#54D2C4" />}
        callToActionSectionData={data.body[6]}
        showValueProposition={true}
      />
    </Layout>
  )
}

export default HomePage
