import React from 'react'
import { ButtonWrapper } from './style'

export const TechnoButton = ({
  logoUrl,
  logoAlt,
  logoIndex,
}: {
  logoUrl: string
  logoAlt: string
  logoIndex: any
}) => {
  return (
    <ButtonWrapper>
      <a
        aria-label={logoAlt}
        href="https://docs.cryptr.co/"
        target="_blank"
        className={`button-${logoIndex}`}
      >
        <img width={48} height={48} src={logoUrl} alt={logoAlt} loading="lazy" />
      </a>
    </ButtonWrapper>
  )
}

export const AskTechnoButton = () => {
  return (
    <ButtonWrapper>
      <a
        aria-label="Ask for a techno"
        className="ask-techno-button"
        href="mailto:jeremie@cryptr.co?subject=Ask for a techno"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path
            fillRule="evenodd"
            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
            clipRule="evenodd"
          />
        </svg>
      </a>
    </ButtonWrapper>
  )
}
